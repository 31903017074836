.flying-switch {
    position: relative !important;
    top: 5px !important;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}

.flying-btn {
    position: relative !important;
    top: 5px !important;
    width: 20px !important;
    height: 20px !important;
}