div.selRegion button.clear-selected-button {
    display: none !important;
}

div.selRegion span:first-child {
    padding-left: 0px;
}

div.selRegion span {
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: unset !important;
}

div.selRegion.disabled div.dropdown-container {
    background-color: #e9ecef;
}