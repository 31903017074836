div.form-group-pricing {
    width: 185px;
}

div.pricing-row {
    display: flex;
}

div.pricing-column {
    width: 275px;
    margin-right: 20px;
}

div.pricing-column:nth-last-child() {
    margin-right: 0px;
}

@media (max-width: 1366px) {
    div.form-group-pricing {
        width: 139px;
    }
    div.pricing-column {
        width: 229px;
        margin-right: 20px;
    }
}