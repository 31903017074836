.pillars {
    border-top: 1px solid #dee2e6;
    padding-top: 25px;
}

.pillars .accordion-header .svg-inline--fa {
    margin-right: 8px;
}

.pillar__apps,
.settings__content__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pillar .pillar__apps > * {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 16px;
}

.pillar__settings {
    border: 1px solid #dee2e6;
    padding: 20px 25px 10px;
    position: relative;
    margin: 15px 0;
}

.settings__title {
    position: absolute;
    top: -12px;
    left: 15px;
    font-size: 14px;
    display: inline-block;
    background-color: #fff;
    padding: 0 10px;
}

.settings__content {
    display: flex;
    flex-wrap: wrap;
}

.settings__content__title {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 12px;
    position: relative;
}

.settings__content__content {
    width: 100%;
    flex: 0 0 100%;
    align-items: flex-start;
}

.pillar .settings__content__content > * {
    margin-bottom: 12px;
}

div.disabled {
    opacity: 0.5;
}

@media (max-width: 575px) {
    .settings__content__content > * {
        width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 576px) {
    .pillars .pillar__apps > * {
        width: 25%;
        flex: 0 0 25%;
    }

    .settings__content__title {
        width: 25%;
        flex: 0 0 25%;
        padding-right: 10px;
    }

    .settings__content__title span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 2;
        padding-right: 12px;
    }

    .settings__content__title:after {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        right: 10px;
        height: 1px;
        border-top: 1px dotted #dee2e6;
        z-index: 1;
    }

    .settings__content__content {
        width: 75%;
        flex: 0 0 75%;
    }

    .settings__content__content > * {
        margin: 0 15px;
    }
}
