fieldset.websites {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    position: relative;
    padding: 1rem 1.5rem;
}

fieldset.websites legend {
    font-size: 1rem;
    position: absolute;
    top: -0.625rem;
    left: 0.75rem;
    background-color: #fff;
    display: inline-block;
    width: max-content;
    padding: 0 0.75rem;
    line-height: 1;
    margin: 0;
}

fieldset.websites div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 1.25rem;
    row-gap: 0.75rem;
}

fieldset.websites div p {
    margin: 0;
}

div.loader {
    display: inline-block;
    vertical-align: middle;
}


div.selection-toolbox {
    position: relative;
}

div.selection-toolbox > span {
    position: relative;
    float: right;
    font-size: small;
}

div.selection-toolbox > span > a {
    margin-left: 15px;
}