.attribute-row {
    display: table;
}

.attribute-row span {
    display: table-cell;
}

.attribute-row span button {
    width: 35px;
    min-width: 35px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.attribute-row span select {
    font-size: small;
}

.attribute-row span input {
    font-size: small;
}

textarea.notice {
    height: 125px;
    font-size: small;
}