.square {
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid black;
}

.listEntry {
    font-size: small !important;
}

.entryTooltip {
    background-color: white;
    padding: 10px;
    min-width: 150px;
    border: 1px solid silver;
}
