div.carousel-indicators,
a.carousel-control-prev,
a.carousel-control-next {
    display: none;
}

button.btn {
    min-width: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

div.pt-4 {
    margin-bottom: 20px;
}

.react-tagsinput {
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-tagsinput-focus {
    border-color: #0d6efd;
    box-shadow: 0 0 0 0.3rem rgba(13, 110, 253, 0.25);
}

.react-tagsinput-remove,
.react-tagsinput-remove:hover {
    color: unset !important;
    text-decoration: unset !important;
}

.react-tagsinput-disabled {
    box-shadow: unset !important;
    border: 1px solid #ced4da;
    background-color: #e9ecef;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}