#product-search {
    max-height: 550px !important;
}

div.product {
    display: table-row;
}

div.product div.thumb {
    display: table-cell;
    width: 100px;
    height: 100px;
}

div.product div.thumb.failed {
    background-image: url("../images/no-image.jpg");
    background-repeat: no-repeat;
}

div.product div.thumb img {
    position: relative;
    max-width: 100px;
    max-height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

div.product div.data {
    display: table-cell;
    vertical-align: top;
}