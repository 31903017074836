span.zeroResults {
    cursor: pointer;
}

span.zeroResults .msg {
    color: saddlebrown;
}

span.zeroResults .reset {
    display: none;
}

span.zeroResults:hover .reset {
    display: inline;
    margin-left: 10px;
    color: gray;
}