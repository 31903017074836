.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 3px;
}

.btn-filter {
    flex: 1 1 calc(100% / 11 - 10px);
    display: inline-flex;
    box-sizing: border-box;
    max-width: fit-content;
    min-width: 109px;
    max-height: 30px;
    margin-bottom: 10px;
    color: #6C757D;
    text-align: center;
    border: 2px solid #6C757D;
    border-radius: 4px;
    cursor: pointer;
    font-size: clamp(13px, 1vw, 13px);
    white-space: nowrap;
    font-weight: 500;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.btn-filter:hover {
    background-color: #166EFD;
    border-color: #166EFD;
    color: #ffffff;
}

.btn-filter:active {
    background-color: #bcc3cb;
    border-color: #bcc3cb;
}

.btn-filter:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(241, 245, 245, 0.5);
}

.btn-filter.active {
    background-color: #ffffff;
    border-color: #166EFD;
    color: #166EFD;
}

.btn-filter.active:hover {
    background-color: #166EFD;
    border-color: #166EFD;
    color: #ffffff;
}

.btn-filter .btn-label {
    padding: 0 1px 0 1px;
    min-width: 80px;
    border-right: 2px solid #6C757D;
    overflow: hidden;
}

.btn-filter.active .btn-label.active {
    border-right: 2px solid #166EFD;
}

.btn-filter.active .btn-label.active:hover {
    border-right: 2px solid #ffffff;
}

.btn-filter .btn-label:hover {
    border-color: #ffffff;
}

.btn-filter .btn-number {
    padding: 0 1px 0 1px;
    text-align: center;
    max-width: fit-content;
    min-width: 20px;
}
