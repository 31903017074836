div.selCountry button.clear-selected-button {
    display: none !important;
}

div.selCountry span:first-child {
    padding-left: 0px;
}

div.selCountry span {
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: unset !important;
}

div.selCountry.disabled div.dropdown-container {
    background-color: #e9ecef;
}